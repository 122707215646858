<template>
    <b-form-select v-model="model" :options="options"/>
</template>

<script>

export default {
    name: 'KpiStatusSelect',
    props: {
        disabled: {type: Boolean, default: false},
        value: {type: Object, default: () => null}
    },
    computed: {
        model: {
            get: function () {
                return this.value;
            },
            set: function (value) {
                this.$emit('input', value);
            }
        },
        options() {
            return this.$store.state.kpi_statuses.statuses.map((status) => {
                return {
                    text: status.name,
                    value: status,
                };
            });
        },
    },
}
</script>
