<template>
    <div>
        <errors-bag-list/>

        <b-card no-body class="mb-2 p-1">
            <b-row>
                <b-col md="12" class="text-right">
                    <b-button :to="routerSalaryBountyTypeList()" class="mr-1">Надбавки и штрафы</b-button>
                    <b-button variant='success' :to='routerSalaryBonusList()' class="mr-1">Настройка вознаграждений</b-button>
                    <b-button variant="primary" @click="fetchSalarySettings" class="mr-1">
                        <b-icon-arrow-repeat font-scale="1.2"/>
                    </b-button>
                </b-col>
            </b-row>
        </b-card>

        <b-table
            :items="items"
            :fields="fields"
            responsive
            stacked="sm"
            head-variant="dark"
        >
            <template #cell(salary_enabled)="row">
                <b-form-checkbox v-model="row.item.salary_enabled" @input="salaryEnabled(row.item)"/>
            </template>

            <template #cell(kpi_status)="row">
                <kpi-status-select v-model="row.item.kpi_status" @input="changeKpiStatus(row.item)"/>
            </template>

            <template #cell(kpi_sanitary_status)="row">
                <kpi-status-select v-model="row.item.kpi_sanitary_status" @input="changeKpiSanitaryStatus(row.item)"/>
            </template>

            <template #cell(actions)="row">
                <b-button :to="routerSalaryRatesList(row.item)" size="sm" variant="primary">
                    <b-icon-gear-fill/>
                </b-button>
            </template>
        </b-table>
    </div>
</template>

<script>
import moment from 'moment';
import ErrorsBagList from '@components/_common/ErrorsBagList';
import KpiStatusSelect from './components/KpiStatusSelect.vue';

import ErrorsBag from '@utils/errorsBag';
import {SALARY_ENDPOINT} from '@utils/endpoints';

const DATE_FORMAT = 'YYYY-MM-DD';

export default {
    name: "SalarySettings",
    components: {ErrorsBagList, KpiStatusSelect},
    data() {
        return {
            ErrorsBag,

            items: [],
            fields: [
                {key: 'work_position', label: 'Должность'},
                {key: 'salary_enabled', label: 'Расчет зарплаты'},
                {key: 'kpi_status', label: 'Расчет KPI'},
                {key: 'kpi_sanitary_status', label: 'Расчет KPI по сан. нормам'},
                {key: 'rate_per_hour', label: 'Стоимость часа, руб.'},
                {
                    key: 'valid_from',
                    label: 'Активна с',
                    formatter: function (value) {
                        return value ? moment(value).format('DD.MM.YYYY') : '';
                    }
                },
                {key: 'actions', label: 'Действия'},
            ]
        }
    },
    methods: {
        routerSalaryBonusList() {
            return {name: 'SalaryBonusList'};
        },
        routerSalaryRatesList(record) {
            return {name: 'SalaryRatesList', params: {id: record.id}};
        },
        routerSalaryBountyTypeList() {
            return {name: 'SalaryBountyType'};
        },
        changeKpiStatus(item) {
            let params = {
                status: {
                    id: item.kpi_status.id
                }
            };

            this.$http
                .put(SALARY_ENDPOINT + '/settings/' + item.id + '/change-kpi-status', params)
                .then(() => {
                    this.$bvToast.toast('Расчет KPI успешно изменен', {variant: 'success'});
                })
                .catch(response => {
                    ErrorsBag.fill(response.body);
                    window.scrollTo({top: 0, behavior: 'smooth'});
                    this.$bvToast.toast('Есть ошибки! Ознакомьтесь со списком вверху страницы', {variant: 'danger'});
                    this.fetchSalarySettings(false);
                });
        },
        changeKpiSanitaryStatus(item) {
            let params = {status: {id: item.kpi_sanitary_status.id}};

            this.$http
                .put(SALARY_ENDPOINT + '/settings/' + item.id + '/change-kpi-sanitary-status', params)
                .then(() => {
                    this.$bvToast.toast('Расчет KPI по сан. нормам успешно изменен', {variant: 'success'});
                })
                .catch(response => {
                    ErrorsBag.fill(response.body);
                    this.$bvToast.toast('Есть ошибки! Ознакомьтесь со списком вверху страницы', {variant: 'danger'});
                    window.scrollTo({top: 0, behavior: 'smooth'});
                    this.fetchSalarySettings(false);
                });

        },
        salaryEnabled(item) {
            let params = {state: item.salary_enabled ? 1 : 0};

            this.$http
                .put(SALARY_ENDPOINT + '/settings/' + item.id + '/salary-enabled', params)
                .then(() => {
                    this.$bvToast.toast('Расчет зарплаты успешно изменен', {variant: 'success'});
                })
                .catch(response => {
                    ErrorsBag.fill(response.body);
                    this.$bvToast.toast('Есть ошибки! Ознакомьтесь со списком вверху страницы', {variant: 'danger'});
                    window.scrollTo({top: 0, behavior: 'smooth'});
                    this.fetchSalarySettings(false);
                });
        },
        prepareItems(items) {
            let preparedItems = [];

            items.forEach((item) => {
                let ratePerHour = item.current_salary_rate ? item.current_salary_rate.rate_per_hour : 0;
                let validFrom = item.current_salary_rate ?
                    moment(item.current_salary_rate.valid_from).format(DATE_FORMAT) : null;

                let preparedItem = {
                    id: item.id,
                    work_position: item.workPosition.name,
                    salary_enabled: item.salary_enabled,
                    kpi_status: item.kpiStatus,
                    kpi_sanitary_status: item.kpiSanitaryStatus,
                    rate_per_hour: ratePerHour,
                    valid_from: validFrom
                };

                preparedItems.push(preparedItem);
            });

            this.items = preparedItems;
        },
        fetchSalarySettings(discard = true) {
            if (discard) {
                ErrorsBag.discard();
            }

            this.items = [];

            let params = {
                order: [{id: 'asc'}],
                appends: ['current_salary_rate'],
                with: ['workPosition', 'kpiStatus', 'kpiSanitaryStatus'],
                limit: 0
            };

            this.$http
                .get(SALARY_ENDPOINT + '/settings', {params})
                .then(response => this.prepareItems(response.data.data.data))
                .catch(response => {
                    ErrorsBag.fill(response.body);
                    this.$bvToast.toast('Есть ошибки! Ознакомьтесь со списком вверху страницы', {variant: 'danger'});
                    window.scrollTo({top: 0, behavior: 'smooth'});
                });

        },
    },
    mounted() {
        this.fetchSalarySettings();
    }
}
</script>
